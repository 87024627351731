<template>
	<div>
		<CustomTable
			:recent-filter="recentFilters"
			:total-pages="totalPages"
			:total-items="totalItems"
			:label-search="$t('UsersAppFilter.search')"
			:label-date="$t('UsersAppFilter.createdDate')"
			:show-filter-status="true"
			:statuses="statuses"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
					>
						{{ header.title }}
					</th>
				</tr>
			</template>
			<template #body>
				<template v-if="users && users.length">
					<tr v-for="(user, index2) in users" :key="`${user.id}-${index2}`">
						<td scope="row" class="text-break text-center see-more">
							{{ user.name }}
						</td>
						<td scope="row" class="text-break text-center see-more">
							{{ user.systemName }}
						</td>
						<td scope="row" class="text-break text-center fit">
							{{ setPhoneNumber(user) }}
						</td>
						<td scope="row" class="text-break text-center see-more">
							{{ user.email }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ getStatus(user.status) }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ formatCurrency(user.revenue) }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ convertDateToDateVN(user.createdDate) }}
						</td>
						<td scope="row" class="fit text-center actions">
							<router-link
								:to="{
									name: 'UsersAppDetail',
									params: { id: user.id },
								}"
								class="link-icon"
							>
								<span
									class="prefix-input d-inline-block mr-3"
									:class="{ 'mr-1-75rem': !checkUserRole(user.role) }"
								>
									<font-awesome-icon :icon="['fas', 'pencil-alt']" :title="$t('Action.Edit')" />
								</span>
							</router-link>
							<template v-if="checkUserRole(user.role)">
								<span
									v-if="checkUserActive(user.status)"
									class="prefix-input d-inline-block"
									@click="handleActiveUser(user.id, USER_STATUS.DEACTIVATE)"
								>
									<font-awesome-icon
										:icon="['fas', 'times']"
										class="cancel-icon"
										:title="$t('Action.Inactive')"
									/>
								</span>
								<span
									v-else
									class="prefix-input d-inline-block"
									@click="handleActiveUser(user.id, USER_STATUS.ACTIVE)"
								>
									<font-awesome-icon
										:icon="['fas', 'check']"
										class="cancel-icon"
										:title="$t('Action.Active')"
									/>
								</span>
							</template>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="11" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTable>
		<CustomModal
			v-if="showConfirmDelete"
			:title="getTitleModal()"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Cancel')"
			@submit-modal="confirm"
			@cancel-modal="cancel"
		>
			<template #content>
				{{ getBodyModal() }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { capitalize, isInteger } from "lodash-es"
import { createNamespacedHelpers } from "vuex"
import { USER_STATUS } from "@/shared/plugins/constants"
import { GET_USERS_APP, CHANGE_STATUS } from "../store/action-types"
import { RESET_STATE } from "../store/mutation-types"
import { USER_ROLE } from "../../../shared/plugins/constants"
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("userApp")
const { mapState: authState } = createNamespacedHelpers("authentication")
export default {
	name: "UserAppManagement",
	data() {
		return {
			USER_STATUS,
			showConfirmDelete: false,
			userId: null,
			actionType: null,
			statuses: [
				{
					name: this.$t("UsersAppFilter.active"),
					value: USER_STATUS.ACTIVE,
				},
				{
					name: this.$t("UsersAppFilter.inactive"),
					value: USER_STATUS.DEACTIVATE,
				},
			],
			headers: [
				{
					title: this.$t("UsersAppFieldTable.name"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersAppFieldTable.systemName"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersAppFieldTable.phone"),
					class: "text-nowrap title text-center fit",
				},
				{
					title: this.$t("UsersAppFieldTable.email"),
					class: "text-nowrap text-center",
				},

				{
					title: this.$t("UsersAppFieldTable.status"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersAppFieldTable.revenue"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersAppFieldTable.createdDate"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersAppFieldTable.actions"),
					class: "fit text-center",
				},
			],
		}
	},
	computed: {
		...mapState(["users", "totalItems", "totalPages", "recentFilters"]),
		...authState(["currentUser"]),
	},
	beforeDestroy() {
		this.RESET_STATE()
	},
	methods: {
		...mapMutations({ RESET_STATE }),
		...mapActions({ GET_USERS_APP, CHANGE_STATUS }),
		async search(params) {
			await this.GET_USERS_APP(params)
		},
		getStatus(status) {
			if (status === USER_STATUS.DEACTIVATE) {
				return this.$t("UsersFilter.inactive")
			}
			return capitalize(status)
		},
		checkUserActive(status) {
			if (status === USER_STATUS.ACTIVE) {
				return true
			}
			return false
		},
		checkUserRole(role) {
			if (
				role === USER_ROLE.SUPER_ADMIN ||
				(this.currentUser.role === USER_ROLE.ADMIN && role === USER_ROLE.ADMIN)
			) {
				return false
			}
			return true
		},
		getTitleModal() {
			if (this.actionType === USER_STATUS.ACTIVE) {
				return this.$t("UserMessage.Header.Active")
			}
			return this.$t("UserMessage.Header.Inactive")
		},
		handleActiveUser(id, type) {
			this.userId = id
			this.actionType = type
			this.showConfirmDelete = true
			this.$store.commit("set", ["modalShow", true])
		},
		async confirm() {
			const params = {
				id: this.userId,
				status: this.actionType,
			}
			await this.CHANGE_STATUS(params)
			this.showConfirmDelete = false
			this.userId = null
			this.actionType = null
			this.$store.commit("set", ["modalShow", false])
			this.search(this.recentFilters)
		},
		cancel() {
			this.showConfirmDelete = false
		},
		getBodyModal() {
			if (this.actionType === USER_STATUS.ACTIVE) {
				return this.$t("UserMessage.Body.Active")
			}
			return this.$t("UserMessage.Body.Inactive")
		},
		formatCurrency(price) {
			let priceFormat = null
			if (isInteger(price)) {
				priceFormat = this.formatNumber(price)
			} else {
				let decimal_pos = price.toString().split(".")

				let left_side = decimal_pos[0]
				let right_side = decimal_pos[1]

				left_side = this.formatNumber(left_side)
				priceFormat = left_side + "." + right_side
			}
			return priceFormat
		},
		formatNumber(n) {
			if (!n) return 0
			// format number 1000000 to 1,234,567
			return n
				.toString()
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		setPhoneNumber(user) {
			if (!user.phone) return null
			if (user.role === USER_ROLE.USER_MEMBER) {
				return `+${user.phoneCode}${user.phone}`
			}
			return `+84${user.phone}`
		},
	},
}
</script>

<style></style>
